import { useCallback, useEffect, useRef, useState } from "react";
import { checkFacility, getZoneHashMap, updateFacility } from "./api";
import { CircularProgress, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { Redirect } from "react-router-dom";
import { searchAssets } from "../../../utils/API/Assets/Assets";
import { searchRadios } from "../../../utils/API/Radios/Radios";
import { useDispatch, useSelector } from "react-redux";
import AdjustIcon from "@mui/icons-material/Adjust";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from "@mui/icons-material/Cancel";
import Card from "@mui/material/Card";
import ConfigureZonesModalContent from "./ConfigureZonesModalContent";
import DefineGrid from "./DefineGrid";
import EditNode from "../ConfigureZone/EditNode/EditNode";
import FacilityImage from "./FacilityImage";
import FacilityImageUpload from "./FacilityImageUpload";
import FacilityImageViewOptions from "./components/FacilityImageViewOptions";
import FacilityOverviewFilterMenu from "./FacilityOverviewFilterMenu";
import Grid from "@mui/material/Grid";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";
import MuiAlert from "@mui/material/Alert";
import NoFacilityPresent from "./NoFacilityPresent";
import ResetFacilityImage from "./ResetFacilityImage";
import Snackbar from "@mui/material/Snackbar";

// ** What this component does **
// This component gives the client the ability to load up an image of their facility and then show
// radios and zones on the given image. The way that we achieve this, is by absolutely positioning the image in the DOM
// We give the image a hard coded height and width value. We then ask the user to specifify a 'zero point' and a 'far point'.
// We then ask for a scale in meters. Using the given information and image, we are able to lay a div (alpha layer) ontop of the image.
// We plot a graph and render the radios and zones ontop of the graph. The graph is then scaled to the image size.

export default function FacilityOverview(props) {
  const dispatchGlobal = useDispatch();

  const classes = {
    card: {
      alignItems: "center",
      backgroundColor: "grey",
      backgroundImage: `linear-gradient(45deg, rgba(255,255,255,.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.2) 50%, rgba(255,255,255,.2) 75%, transparent 75%, transparent)`,
      backgroundSize: "20px 20px",
      display: "flex",
      height: "74vh",
      paddingBottom: "10px",
      paddingTop: "10px",
      position: "relative",
      width: "100%",
    },
    button: {
      margin: "1rem 0",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      placeItems: "center",
    },
    loading: {
      textAlign: "center",
    },
    image: {
      pointerEvents: "none",
      position: "absolute",
    },
    overViewContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "1rem 0",
    },
    titleContainer: {
      display: "flex",
      marginBottom: "1rem",
      justifyContent: "space-between",
    },
  };
  const { customs } = useSelector((state) => state.organization, isEqual);

  const { apiUrl, history = {}, token, organizationId, userRoles = {} } = props;
  const { location = {} } = history;
  const { state: locationState = {} } = location;
  const { selectedFacility = {} } = locationState;
  const { facilityId, name = "" } = selectedFacility;
  const [state, setState] = useState({
    assets: [],
    facilityImage: null,
    facilityImageId: null,
    farPoint: { x: 0, y: 0, disablePlunger: false },
    filters: {
      customs: [],
      isSearchForDevice: true,
      limit: 1000,
      tag: "",
    },
    grid: { x: 0, y: 0 },
    loading: true,
    radioHashMap: {},
    selectedNode: {},
    selectedFacility: selectedFacility,
    // All Zones Hash Map is a collection of all zones associaited with the facility.
    // Its purpose is to allow us to quickly find the zone information while someone is editing a fixed node.
    // Since the zone info does not come along with that payload
    allZonesHashMap: {},
    facilityZoneHashMap: {},
    viewOptions: {
      showAssets: true,
      showRadios: true,
      showZones: true,
    },
    zeroPoint: { x: 0, y: 0, disablePlunger: false },
  });
  const { farPoint, zeroPoint } = state;
  const [snackbarState, setSnackbarState] = useState({
    content: null,
    error: false,
    open: false,
    severity: "success",
  });
  const [modalState, setModalState] = useState({
    modalShow: false,
    title: "",
  });
  const [redirect, setRedirect] = useState({
    isRedirect: false,
    state: {},
    pathName: "",
  });
  const [img, setImg] = useState({ height: 0, isLoaded: false, width: 0 });
  const ref = useRef(null);
  const switchModal = (modal) => {
    switch (modal) {
      case "Configure Zones":
        return (
          <ConfigureZonesModalContent
            setModalState={setModalState}
            setRedirect={setRedirect}
            selectedFacility={state.selectedFacility}
          />
        );
      case "Define Grid":
        return (
          <DefineGrid
            {...props}
            facilityId={facilityId}
            dispatchGlobal={dispatchGlobal}
            handleSnackbar={handleSnackbar}
            hasFormFields={true}
            setModalState={setModalState}
            setState={setState}
            state={state}
          />
        );
      case "Filter Menu":
        return (
          <FacilityOverviewFilterMenu
            {...props}
            customs={customs}
            hasFormFields={true}
            onSubmit={filterZones}
            setModalState={setModalState}
            setState={setState}
            state={state}
          />
        );
      case "Mount Point Information":
        return (
          <EditNode
            {...props}
            dialogClose={() => {
              setModalState((prevState) => ({
                ...prevState,
                modalShow: false,
              }));
            }}
            handleAlertModal={() => {}}
            hasFormFields={true}
            onSuccess={setFacility}
            showTitle={true}
            state={state}
            zoneInfo={state.allZonesHashMap[state.selectedNode.zoneId]}
          />
        );
      case "Reset Facility Image":
        return (
          <ResetFacilityImage
            {...props}
            dispatchGlobal={dispatchGlobal}
            facilityId={facilityId}
            handleSnackbar={handleSnackbar}
            selectedFacility={state.selectedFacility}
            setModalState={setModalState}
            state={state}
            setState={setState}
          />
        );
      default:
        return;
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  };

  const setFacility = useCallback(() => {
    checkFacility({ ...props, facilityId }).then((results) => {
      let radioHashMap = {};

      // TODO - After PR"ing this code. Ive been notified that we are moving zones into the REDUX sture.
      // Will need to circle back and migrate the code over to the REDUX store.

      // Facility Zone Hasmap is suppose to show the avialable zones within a certian facility.
      // Its a hasmap because I need to know what zones are available and what their show state is.
      // I dont know if this is going to be a requirment. But im going to prep for it ahead of time.
      let facilityZoneHashMap = {};

      if (results.success) {
        const { facility = {} } = results;
        const { images = [], propertiesMap = {}, zones = [] } = facility;
        const {
          facilityImage = {
            zeroPoint: { x: 0, y: 0, disablePlunger: false },
            grid: { x: 0, y: 0 },
          },
        } = propertiesMap;

        // This is used to query radios
        const zoneIds = [];

        // Populate the facilityZoneHashMap - these are rendered on the image and will have a show hide state.
        zones.forEach((zone) => {
          // Push to zoneIds for the query
          zoneIds.push(zone.zoneId);

          // Populate facilityZoneHashMap
          facilityZoneHashMap[zone.zoneId] = {
            ...zone,
            color: zone.zoneType === "processing" ? "red" : "#947447",//brown
            count: 0,
            opacity: 0.5,
            show: true,
          };
        });

        // TODO: Everything is wild right now. I have no idea if i need to be rendering fixedNodes.
        // I was at some point. But now im not. Going to leave this here just incase.
        // If this gets a thumbs up with this comment out. Then we can remove.
        // Extract zoneID's out of the fixed nodes so we can
        // find the associated radios
        // fixedNodes.forEach((node = {}) => {
        //   // If the zoneId is NOT in the zoneIDs array, push it on up
        //   if (!zoneIds.includes(node.zoneId)) {
        //     zoneIds.push(node.zoneId);
        //   }

        //   // We also set up the fixedNodeHashMap for the searchRadios function
        //   fixedNodeHashMap[node.name] = { ...node, children: {} };
        // });

        searchRadios(props, zoneIds, {
          elasticSearchQuery: {
            bool: {
              should: [
                {
                  terms: {
                    primary_p_zone_id: zoneIds,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
        }).then((res) => {
          const { radios = [] } = res;
          const nodesArray = [];

          // We no longer need to assign fixedNodes over to fixedNodes.
          // Divvy's up the radios to the appropiate fixedNode
          // radios.forEach((radio) => {
          //   if (fixedNodeHashMap[radio.node]) {
          //     fixedNodeHashMap[radio.node].children[radio.radioId] = radio;
          //   }
          // });

          // We need to create a radioHashMap so we can quickly find the radio information
          radios.forEach((radio) => {
            radioHashMap[radio.radioId] = {
              ...radio,
              show: true,
              color: "blue",
              count: 0,
            };
          });

          setState((prevState) => ({
            ...prevState,
            ...facilityImage,
            facilityImage:
              images.find((image) => image.imageType === "PNG")?.url || null,
            facilityImageId:
              images.find((image) => image.imageType === "PNG")?.imageId ||
              null,
            facilityZoneHashMap,
            fixedNodes: nodesArray,
            radioHashMap,
            loading: false,
          }));
        });
      } else {
        setState((prevState) => ({ ...prevState, loading: false }));
      }
    });
  }, [facilityId, props]);

  const permissions = {
    editFacilityImage: userRoles["Edit Facility Image"],
    editFarPoint: userRoles["Edit Far Point"],
    editZeroPoint: userRoles["Edit Zero Point"],
  };
  const { editFacilityImage, editFarPoint, editZeroPoint } = permissions;

  const handleImageLoad = (event) => {
    const height = ref.current.clientHeight;
    const width = event.target.clientWidth;
    setImg({ height, isLoaded: true, width });
  };

  function clearSearchResults() {
    setState((prevState) => {
      const newState = { ...prevState };
      // Change all zones back to their default color
      Object.values(newState.facilityZoneHashMap).forEach((zoneData) => {
        const zone = { ...zoneData }; // Create a new object
        zone.color = zone.internalZoneType === "processing" ? "red" : "#947447";//brown
        zone.count = 0;
        zone.opacity = 0.5;
        newState.facilityZoneHashMap[zone.zoneId] = zone; // Update the zone in newState
      });
      // Clear out the search results
      newState.assets = [];
      return newState;
    });
  }

  function handleSnackbar(content, error) {
    setSnackbarState((prevState) => ({
      ...prevState,
      content,
      error,
      open: true,
    }));
  }

  function filterZones() {
    // Clear out previous search results
    clearSearchResults();

    // Search for assets
    searchAssets({ apiUrl, isInventory: true, token, organizationId, facilityId: state.selectedFacility.facilityId }, state).then((res) => {
      // When the assets return, we need to see what assets are in a zone.
      // And then change the color of that zone to blue
      // Set the results in state
      const { assets } = res;

      const updatedHashMap = { ...state.facilityZoneHashMap };

      //reset the color and opacity of all zones as they were originally
      state.selectedFacility.zones.forEach((zone) => {
        updatedHashMap[zone.zoneId] = zone;
      });

      assets.forEach((asset) => {
        const { zone = "" } = asset;
        const zoneObject = state.facilityZoneHashMap[zone.zoneId];
        const { zoneType = "" } = zoneObject || {};

        if (zoneType === "processing") {
          //update the color of the zone to purple and the opacity to 0.5
          updatedHashMap[zone.zoneId] = {
            ...zoneObject,
            color: "purple",
            count: updatedHashMap[zone.zoneId].count
              ? updatedHashMap[zone.zoneId].count + 1
              : 1,
            opacity: 0.5,
          };
        } else if (zoneType === "target") {
          // update the color of the zone to light blue and the opacity to 0.5
          updatedHashMap[zone.zoneId] = {
            ...zoneObject,
            color: "#03f8fc",
            count: updatedHashMap[zone.zoneId].count
              ? updatedHashMap[zone.zoneId].count + 1
              : 1,
            opacity: 0.5,
          };
        }
      });

      setState((prevState) => {
        return {
          ...prevState,
          assets: assets,
          facilityZoneHashMap: updatedHashMap,
        };
      });

      // Close the modal
      setModalState((prevState) => ({
        ...prevState,
        modalShow: false,
      }));
    });
  }

  useEffect(() => {
    setFacility();
    getZoneHashMap(props).then((res) =>
      setState((prevState) => ({ ...prevState, allZonesHashMap: res }))
    );
  }, [props, setFacility]);

  return (
    <Grid container sx={{ justifyContent: "center" }} xs={12}>
      {/* SnackBar */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarState.error ? "error" : "success"}
        >
          {snackbarState.content}
        </MuiAlert>
      </Snackbar>

      {/* Modal */}
      <ModalDialog
        handleClose={() => {
          setModalState({ modalShow: false, title: "", content: "" });
        }}
        open={modalState.modalShow}
        title={modalState.title}
        content={switchModal(modalState.title)}
      />
      {redirect.isRedirect ? (
        <Redirect
          to={{
            pathname: "/facilities/",
            state: { ...redirect.state },
          }}
        />
      ) : null}

      {/* Header / Back to Facilities / Zero Point button */}
      <Grid sx={classes.titleContainer} item xs={12}>
        {/* Header */}
        <Grid item>
          <Typography component="h1" sx={{ textAlign: "left" }} variant="h4">
            {name}
          </Typography>
        </Grid>

        <Grid sx={classes.buttonContainer} item>
          <HoverIconButton
            cypressId="facilities-overview-btn-back"
            handleClick={() => {
              setRedirect({
                isRedirect: true,
                pathName: "/facilities",
                state: {},
              });
            }}
            icon={<ArrowBackIcon/>}
            iconDirection="left"
            text="Back to Facilities"
          />
        </Grid>
      </Grid>

      {/* Filter / Clear Search Buttons */}
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alighItems: "center",
        }}
        xs={12}
      >
        <Grid sx={{ display: "flex", alignItems: "center" }}>
          <MaterialUiButton
            // Disable button if there is no facility image
            disabled={!state.facilityImage}
            label="Filters"
            onClick={() =>
              setModalState((prevState) => ({
                ...prevState,
                title: "Filter Menu",
                modalShow: true,
              }))
            }
          />
          <MaterialUiButton
            color="cancel"
            disabled={!state.facilityImage}
            label="Clear Values"
            onClick={() => clearSearchResults()}
            sx={{ marginLeft: "1rem" }}
          />
          {/*redirects to configure zones for current facility */}
          <MaterialUiButton
            // disabled={!state.facilityImage}
            label="Configure Zones"
            onClick={() =>
              setRedirect({
                isRedirect: true,
                pathName: "/facilities",
                state: {
                  facility: selectedFacility,
                  viewZones: true,
                },
              })
            }
            sx={{ marginLeft: "1rem" }}
          />
        </Grid>
        <Grid sx={{ display: "flex", gap: "14px", alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            Zero point
            <div 
              className="box"
              style={{ color: "green", width: "1px", marginRight: "14px" }}
            >
              <CancelIcon />
            </div>
          </div>

          <div style={{ display: "flex" }}>
            Far point
            <div
              className="box"
              style={{ color: "red", width: "1px", marginRight: "14px" }}
            >
              <AdjustIcon />
            </div>
          </div>

          {/* Reset Image Button */}
          {editFacilityImage ? (
            <MaterialUiButton
              sx={classes.button}
              cypressId="facilities-overview-btn-reset-image"
              disabled={!state.facilityImage}
              label="Reset Facility Image"
              size="small"
              onClick={() => {
                setModalState((prevState) => ({
                  ...prevState,
                  title: "Reset Facility Image",
                  modalShow: true,
                }));
              }}
            />
          ) : null}

          {/* Zero Point Button */}
          {editZeroPoint ? (
            <MaterialUiButton
              sx={classes.button}
              // Disable button if no facility image is present
              disabled={!state.facilityImage}
              cypressId={`facilities-overview-btn${
                zeroPoint.disablePlunger ? "-reset" : "-set"
              }-zero-point`}
              label={
                zeroPoint.disablePlunger ? "Reset Zero Point" : "Set Zero Point"
              }
              size="small"
              onClick={() => {
                if (!zeroPoint.disablePlunger) {
                  // if zeroPoint marker is visible
                  //set point and disable the plunger
                  setState((prevState) => ({
                    ...prevState,
                    zeroPoint: {
                      ...prevState.zeroPoint,
                      disablePlunger: true,
                    },
                  }));
                  updateFacility(
                    { ...props, dispatchGlobal, facilityId, state },
                    {
                      zeroPoint: {
                        ...zeroPoint,
                        disablePlunger: true,
                      },
                    }
                  );

                  // If both plungers are set, have the user define their grid
                  if (farPoint.disablePlunger && !zeroPoint.disablePlunger) {
                    setModalState({
                      modalShow: true,
                      title: "Define Grid",
                    });
                  }
                } else {
                  //invoke api call to reset the zeroPoint
                  updateFacility(
                    { ...props, dispatchGlobal, facilityId, state },
                    {
                      zeroPoint: null,
                    }
                  );
                  setState((prevState) => ({
                    ...prevState,
                    zeroPoint: {
                      ...prevState.zeroPoint,
                      disablePlunger: false,
                    },
                  }));
                }
              }}
            />
          ) : null}

          {/* Far Point Button */}
          {editFarPoint ? (
            <MaterialUiButton
              sx={classes.button}
              cypressId={`facilities-overview-btn${
                farPoint.disablePlunger ? "-reset" : "-set"
              }-far-point`}
              // Disable button if no facility image is present
              disabled={!state.facilityImage}
              label={
                farPoint.disablePlunger ? "Reset Far Point" : "Set Far Point"
              }
              size="small"
              onClick={() => {
                if (!farPoint.disablePlunger) {
                  setState((prevState) => ({
                    ...prevState,
                    farPoint: { ...prevState.farPoint, disablePlunger: true },
                  }));
                  updateFacility(
                    { ...props, dispatchGlobal, facilityId, state },
                    { farPoint: { ...farPoint, disablePlunger: true } }
                  );

                  // If both plungers are set, have the user define their grid
                  if (!farPoint.disablePlunger && zeroPoint.disablePlunger) {
                    setModalState({
                      modalShow: true,
                      title: "Define Grid",
                    });
                  }
                } else {
                  //invoke api call to reset the zeroPoint
                  updateFacility(
                    { ...props, dispatchGlobal, facilityId, state },
                    {
                      farPoint: null,
                    }
                  );
                  setState((prevState) => ({
                    ...prevState,
                    farPoint: { ...prevState.farPoint, disablePlunger: false },
                  }));
                }
              }}
            />
          ) : null}
        </Grid>
      </Grid>

      {/* Facility Overview */}
      <Card raised sx={classes.card} ref={ref}>
        {!facilityId ? (
          <NoFacilityPresent />
        ) : state.loading ? (
          <Grid sx={classes.loading} item xs={12}>
            <CircularProgress />
          </Grid>
        ) : state.facilityImage ? (
          <FacilityImage
            facilityImage={state.facilityImage}
            handleImageLoad={handleImageLoad}
            img={img}
            isFacilityPage={true}
            setModalState={setModalState}
            state={state}
            setState={setState}
          />
        ) : (
          <FacilityImageUpload
            {...props}
            facilityId={facilityId}
            handleSnackbar={handleSnackbar}
            setState={setState}
            dispatchGlobal={dispatchGlobal}
          />
        )}
      </Card>

      {/* Show Options for Image */}
      <Grid item xs={12}>
        <FacilityImageViewOptions
          isFacilityPage={true}
          state={state}
          setState={setState}
        />
      </Grid>
    </Grid>
  );
}
